import { Icon, Typography, FlexBox, Box } from '@vp/swan'
import { PillarProps } from '../types'

export const Pillar = ({ icon, heading, description, justifyContent } : PillarProps) => {
  return (
    <FlexBox style={{ height: '100%' }} alignItems='center' justifyContent={justifyContent} paddingTop={4}>
      <Box style={{ padding: '18px', borderRadius: '50%' }} position='relative' bgc='accent'>
        <Icon iconType={icon} size='24p' aria-hidden='true' />
      </Box>
      <FlexBox flexDirection='column' paddingLeft={5}>
        <Typography fontSkin='title-item'>{heading}</Typography>
        <Typography fontSkin='body-small'>{description}</Typography>
      </FlexBox>
    </FlexBox>
  )
}
