import { PipeSeparator, Link, Typography, Divider, BoundedContent, Span } from '@vp/swan'
import { PromoBannerProps } from '../types'
import { createOnClickTrackingHandler, TrackingLinkConfig } from '../../utils/trackingUtils'

export const PromoSlimBanner = (props : PromoBannerProps) => {
  const { promoHeading, promoCode, promoEndDate, backgroundColor, promoFirstCta, promoSecondCta, promoLegalText, promoLegalLink } = props
  const validCTA1 = promoFirstCta?.text && promoFirstCta?.targetUrl
  const validCTA2 = promoSecondCta?.text && promoSecondCta?.targetUrl

  const getTrackingConfig = (destinationPath: string, ctaValue: string, ctaPosition: number): TrackingLinkConfig => {
    const pageZone = 'Slim Banner Section'
    const sourcePagePath = typeof window !== 'undefined' ? window.location.pathname : '/'
    return {
      eventName: 'Navigation Clicked',
      linkAttributes: {
        'data-section': `Content:${pageZone}`,
        'data-position': ctaPosition ?? 0,
        'data-translation': ctaValue,
      },
      properties: {
        category: 'Home Page',
        label: `Content:${pageZone}`,
        eventDetail: `${sourcePagePath};${destinationPath};${pageZone};${ctaValue}`,
        navigationDetail: ctaValue,
      },
    }
  }
  return (
    <>
      <BoundedContent bgc={backgroundColor || 'warm-white'} paddingY={4}>
        <Typography fontSkin='title-subsection' textAlign='center'>
          {promoHeading}{promoCode && (
            <>
              <Span paddingX={3}>
                <PipeSeparator />
              </Span>
              {promoCode}
            </>
          )}
        </Typography>
        {validCTA1 && (
          <Typography textAlign='center' paddingTop={3}>
            {validCTA1 && (
              <Link
                href={promoFirstCta.targetUrl}
                onClick={createOnClickTrackingHandler(getTrackingConfig(promoFirstCta.targetUrl, promoFirstCta.text, 0), promoFirstCta.targetUrl)}
              >
                {promoFirstCta.text}
              </Link>
            )}
            {validCTA2 && (
              <>
                <Span paddingX={3}> <PipeSeparator /></Span>
                <Link
                  href={promoSecondCta.targetUrl}
                  onClick={createOnClickTrackingHandler(getTrackingConfig(promoSecondCta.targetUrl, promoSecondCta.text, 1), promoSecondCta.targetUrl)}
                >
                  {promoSecondCta.text}
                </Link>
              </>)}
          </Typography>
        )}
        {(promoEndDate || promoLegalText || promoLegalLink) && (
          <Typography fontSkin='footnote' textAlign='center' paddingTop={3}>
            {promoEndDate}{promoEndDate && (promoLegalText || promoLegalLink) && <Span paddingX={3}><PipeSeparator /></Span>}{promoLegalText}{promoLegalText && promoLegalLink?.text && '\u00A0'}{promoLegalLink && <Link href={promoLegalLink.targetUrl}>{promoLegalLink.text}</Link>}
          </Typography>
        )}
      </BoundedContent>
      {!backgroundColor ? <BoundedContent paddingX={0}><Divider /></BoundedContent> : null}
    </>
  )
}
