import { Pillar } from './Pillar'
import { Column, GridContainer, Hidden, Row } from '@vp/swan'
import { PillarBannerProps } from '../types'

export const PillarRow = ({ pillarData }: PillarBannerProps) => {
  return (
    <Hidden xs>
      <GridContainer paddingBottom={4}>
        <Row>
          {pillarData.map((pillar, index) => {
            const { icon, heading, description } = pillar
            if (icon && heading && description) {
              return (
                <Column key={index} span={4}>
                  <Pillar
                    icon={icon}
                    heading={heading}
                    description={description}
                  />
                </Column>
              )
            }
            return null
          })}
        </Row>
      </GridContainer>
    </Hidden>
  )
}
