import { PillarRow } from './PillarRow'
import { PillarCarousel } from './PillarCarousel'
import { BoundedContent, Divider, SWAN_STYLE_KEY_MAP } from '@vp/swan'
import { CarouselPillarBannerProps } from '../types'
import { useSwanStyleKeys } from '@vp/ubik-context'

export const PillarSlimBanner = ({ pillarData, carouselA11yText }: CarouselPillarBannerProps) => {
  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.hidden,
    SWAN_STYLE_KEY_MAP.visible
  ])
  return (
    <>
      <BoundedContent>
        <PillarCarousel pillarData={pillarData} carouselA11yText={carouselA11yText} />
        <PillarRow pillarData={pillarData} />
      </BoundedContent>
      <BoundedContent paddingX={0}>
        <Divider />
      </BoundedContent>
    </>
  )
}
