import { Carousel, CarouselSlide, Visible } from '@vp/swan'
import { Pillar } from './Pillar'
import { TokenEngine } from '@vp/vp-js-token-engine'
import { CarouselPillarBannerProps } from '../types'

const tokenEngine = new TokenEngine()

export const PillarCarousel = ({ pillarData, carouselA11yText }: CarouselPillarBannerProps) => {
  return (
    <Visible xs>
      <Carousel
        slidesToShow={1}
        progressIndicator='dots'
        arrows={false}
        aria-label={carouselA11yText.label}
        aria-roledescription={carouselA11yText.role}
        accessibleTextForVisibleSlides={carouselA11yText.visibleSlides}
        accessibleTextForDots={[
          tokenEngine.replace(carouselA11yText.dotsTemplate, { slideNumber: '1' }),
          tokenEngine.replace(carouselA11yText.dotsTemplate, { slideNumber: '2' }),
          tokenEngine.replace(carouselA11yText.dotsTemplate, { slideNumber: '3' }),
        ]}
      >
        {pillarData.map((pillar, index) => {
          const { icon, heading, description } = pillar
          if (icon && heading && description) {
            return (
              <CarouselSlide key={index} aria-roledescription={carouselA11yText.slideRole}>
                <Pillar
                  icon={icon}
                  heading={heading}
                  description={description}
                  justifyContent='center'
                />
              </CarouselSlide>
            )
          }
          return null
        })}
      </Carousel>
    </Visible>
  )
}
